<i18n>
ru:
  unknown: 'Неизвестный попап {popup}'
ua:
  unknown: 'Невідомий попап {popup}'
us:
  unknown: 'Unknown popup {popup}'
</i18n>

<template>
  <AnimatePresence>
    <div
      id="v-popup"
      class="v-popup"
      v-on-key-press="toggleKeyPress"
      v-if="popupStore.popupVisible"
      @mousedown="outsideClick"
      key="v-popup"
    >
      <motion.div
        :initial="{ opacity: 0, scale: 0.85 }"
        :animate="{ opacity: 1, scale: 1 }"
        :exit="{ opacity: 0, scale: 0.85 }"
        :transition="{
          duration: 0.4
        }"
        id="v-popup-wrapper"
        class="v-popup-wrapper"
        :class="[
          className,
          captchaVisible ? 'v-popup-wrapper__recaptcha' : '',
          popupStore.shake ? 'v-popup-shake' : '',
          popupPlaceCloseButtonInside
            ? 'v-popup-close-inside'
            : 'v-popup-close-outside'
        ]"
        :data-test-id="autoTest.dataTestId"
      >
        <div
          v-if="popupStore.popupVisible"
          :key="hash"
          class="v-popup-content"
          :class="{
            'v-popup-content--close-outside': !popupPlaceCloseButtonInside
          }"
        >
          <div
            v-if="popupStore.popupClosable"
            class="v-popup-close-button"
            :class="[
              popupPlaceCloseButtonInside
                ? 'v-popup-close-button-inside'
                : 'v-popup-close-button-outside'
            ]"
            data-test-id="popup-close-button"
            @click="async () => await popupStore.closePopup()"
          >
            <ui-icon
              color="currentColor"
              :size="42"
              name="close"
            />
          </div>
          <lazy-account-change-password
            v-if="popupStore.popupComponent === 'accountChangePasswordPopup'"
          />
          <lazy-account-change-phone
            v-else-if="popupStore.popupComponent === 'accountChangePhonePopup'"
          />
          <lazy-account-delete-account
            v-else-if="popupStore.popupComponent === 'accountDeletePopup'"
          />
          <lazy-account-management
            v-else-if="popupStore.popupComponent === 'accountManagementPopup'"
          />
          <lazy-account-personal-cabinet-parts-edit-profile
            v-else-if="popupStore.popupComponent === 'accountEditProfilePopup'"
          />
          <lazy-account-personal-cabinet-parts-order-details-popup
            v-else-if="popupStore.popupComponent === 'accountOrderDetailsPopup'"
            :order="popupStore.popupProperties.get('order')"
          />
          <lazy-account-personal-cabinet-parts-order-repeat-popup
            v-else-if="popupStore.popupComponent === 'accountOrderRepeatPopup'"
            :order="popupStore.popupProperties.get('order')"
          />
          <lazy-account-personal-cabinet-parts-order-repeat-redirect-popup
            v-else-if="popupStore.popupComponent === 'orderRepeatRedirectPopup'"
            :order="popupStore.popupProperties.get('order')"
            :title-key="popupStore.popupProperties.get('titleKey')"
          />
          <lazy-account-personal-cabinet-parts-points-history
            v-else-if="popupStore.popupComponent === 'accountPointsHistory'"
          />
          <lazy-account-personal-cabinet-parts-ranks-popup
            v-else-if="popupStore.popupComponent === 'accountRanksPopup'"
          />
          <lazy-account-points-menu-popup
            v-else-if="popupStore.popupComponent === 'accountPointsMenuPopup'"
          />
          <lazy-account-saved-addresses-popup
            v-else-if="popupStore.popupComponent === 'savedAddressesPopup'"
            :address="popupStore.popupProperties.get('address')"
          />
          <lazy-address-city-selector-popup
            v-else-if="popupStore.popupComponent === 'citySelectorPopup'"
          />
          <lazy-address-selector-popup
            v-else-if="popupStore.popupComponent === 'addressSelectorPopup'"
            :from-cart="popupStore.popupProperties.get('fromCart')"
            :on-entrance="popupStore.popupProperties.get('onEntrance')"
            :on-product-add="popupStore.popupProperties.get('onProductAdd')"
          />
          <lazy-cart-delivery-time-rath-celerdain-popup
            v-else-if="
              popupStore.popupComponent === 'deliveryTimeRathCelerdainPopup'
            "
          />
          <lazy-cart-delivery-time-rath-dinen-popup
            v-else-if="popupStore.popupComponent === 'deliveryTimeRathDinenPopup'"
          />
          <lazy-cart-entwash-birthday-gift-popup
            v-else-if="popupStore.popupComponent === 'cartBirthdayGiftPopup'"
          />
          <lazy-cart-entwash-changed-products-popup
            v-else-if="popupStore.popupComponent === 'cartChangedProductsPopup'"
            :content="popupStore.popupProperties.get('content')"
            :ok-function="popupStore.popupProperties.get('okFunction')"
          />
          <lazy-cart-entwash-cleanup-popup
            v-else-if="popupStore.popupComponent === 'cartCleanupPopup'"
          />
          <lazy-cart-entwash-pay-method-bindings-popup
            v-else-if="popupStore.popupComponent === 'payMethodBindingsPopup'"
          />
          <lazy-cart-partial-pay-popup
            v-else-if="popupStore.popupComponent === 'partialPayPopup'"
          />
          <lazy-cart-qr-pay-popup
            v-else-if="popupStore.popupComponent === 'qrPayPopup'"
            :order-id="popupStore.popupProperties.get('orderId')"
            :total-sum="popupStore.popupProperties.get('totalSum')"
          />
          <lazy-cart-rhudaur-cash-change
            v-else-if="popupStore.popupComponent === 'cartCashChange'"
          />
          <lazy-clientside-offer-info-popup
            v-else-if="popupStore.popupComponent === 'offerInfoPopup'"
          />
          <lazy-clientside-promo-in-url-popup
            v-else-if="popupStore.popupComponent === 'promoInUrlPopup'"
            :code="popupStore.popupProperties.get('code')"
            :subtitle="popupStore.popupProperties.get('subtitle')"
            :title="popupStore.popupProperties.get('title')"
          />
          <lazy-common-gallery-popup
            v-else-if="popupStore.popupComponent === 'galleryPopup'"
            :active-number="popupStore.popupProperties.get('activeNumber')"
            :auto-play-delay="popupStore.popupProperties.get('autoPlayDelay')"
            :images="popupStore.popupProperties.get('images')"
            :show-thumbs="popupStore.popupProperties.get('showThumbs')"
          />
          <lazy-header-parts-easter-egg-popup
            v-else-if="popupStore.popupComponent === 'easterEggPopup'"
          />
          <lazy-header-parts-qr-app-popup
            v-else-if="popupStore.popupComponent === 'qrAppPopup'"
          />
          <lazy-home-banners-minas-tirith-popup
            v-else-if="popupStore.popupComponent === 'bannerStories'"
            :index="popupStore.popupProperties.get('index')"
          />
          <lazy-home-check-order-tips-popup
            v-else-if="popupStore.popupComponent === 'orderTipsPopup'"
            :tips-link="popupStore.popupProperties.get('tipsLink')"
          />
          <lazy-maps-saved-addresses
            v-else-if="popupStore.popupComponent === 'mapsSavedAddressesPopup'"
            :address="popupStore.popupProperties.get('address')"
          />
          <lazy-menu-gifts-popup
            v-else-if="popupStore.popupComponent === 'menuGiftsPopup'"
            :allow-multiple="popupStore.popupProperties.get('allowMultiple')"
            :gifts="popupStore.popupProperties.get('gifts')"
            :type="popupStore.popupProperties.get('type')"
          />
          <lazy-menu-product-base-page
            v-else-if="popupStore.popupComponent === 'menuProductPopup'"
            :product-id="popupStore.popupProperties.get('productId')"
          />
          <lazy-menu-product-constructor-card-page
            v-else-if="
              popupStore.popupComponent === 'menuConstructorPopup' ||
              popupStore.popupComponent === 'menuConstructorPopupSmall'
            "
            :constructor-id="popupStore.popupProperties.get('constructorId')"
          />
          <lazy-menu-product-slots-half-page
            v-else-if="popupStore.popupComponent === 'menuSlotsHalfPopup'"
            :slot-id="popupStore.popupProperties.get('slotId')"
          />
          <lazy-menu-product-slots-lunch-mobile-popup
            v-else-if="popupStore.popupComponent === 'menuSlotsLunchMobilePopup'"
            :select-slot="popupStore.popupProperties.get('selectSlot')"
            :selected-slot-id="popupStore.popupProperties.get('selectedSlotId')"
            :slot-product="popupStore.popupProperties.get('slot')"
          />
          <lazy-menu-product-slots-lunch-page
            v-else-if="popupStore.popupComponent === 'menuSlotsLunchPopup'"
            :slot-id="popupStore.popupProperties.get('slotId')"
          />
          <lazy-menu-product-slots-quarter-page
            v-else-if="popupStore.popupComponent === 'menuSlotsQuarterPopup'"
            :slot-id="popupStore.popupProperties.get('slotId')"
          />
          <lazy-menu-search
            v-else-if="popupStore.popupComponent === 'menuSearchPopup'"
          />
          <lazy-page-feedback-image-popup
            v-else-if="popupStore.popupComponent === 'feedbackImagePopup'"
            :img-url="popupStore.popupProperties.get('imgUrl')"
          />
          <lazy-page-feedback-popup
            v-else-if="popupStore.popupComponent === 'feedbackPopup'"
          />
          <lazy-page-table-reservation-form
            v-else-if="popupStore.popupComponent === 'tableReservationPopup'"
          />
          <lazy-page-vacancies-popup
            v-else-if="popupStore.popupComponent === 'vacancyPopup'"
            :id="popupStore.popupProperties.get('id')"
          />
          <lazy-popup-common-confirm
            v-else-if="popupStore.popupComponent === 'confirmPopup'"
            :message="popupStore.popupProperties.get('message')"
            :no-function="popupStore.popupProperties.get('noFunction')"
            :no-text="popupStore.popupProperties.get('noText')"
            :title="popupStore.popupProperties.get('title')"
            :type="popupStore.popupProperties.get('type')"
            :yes-function="popupStore.popupProperties.get('yesFunction')"
            :yes-or-no="popupStore.popupProperties.get('yesOrNo')"
            :yes-text="popupStore.popupProperties.get('yesText')"
          />
          <lazy-popup-common-error-popup
            v-else-if="popupStore.popupComponent === 'errorPopup'"
            :data-test-id="popupStore.popupProperties.get('dataTestId')"
            :message="popupStore.popupProperties.get('message')"
            :title="popupStore.popupProperties.get('title')"
            :type="popupStore.popupProperties.get('type')"
          />
          <lazy-popup-common-input-popup
            v-else-if="popupStore.popupComponent === 'inputPopup'"
            :data-test-id="popupStore.popupProperties.get('dataTestId')"
            :initial-text="popupStore.popupProperties.get('initialText')"
            :on-confirm="popupStore.popupProperties.get('onConfirm')"
            :title="popupStore.popupProperties.get('title')"
          />
          <lazy-popup-common-message-popup
            v-else-if="
              popupStore.popupComponent === 'messagePopup' ||
              popupStore.popupComponent === 'messagePopupStandardSize'
            "
            :data-test-id="popupStore.popupProperties.get('dataTestId')"
            :message="popupStore.popupProperties.get('message')"
            :title="popupStore.popupProperties.get('title')"
            :type="popupStore.popupProperties.get('type')"
          />
          <div
            v-else
            class="v-error-color"
            v-html="
              translate('observer.unknown', {
                popup: popupStore.popupComponent ?? ''
              })
            "
          />
        </div>
      </motion.div>
    </div>
  </AnimatePresence>
</template>

<script setup lang="ts">
import type { PopupComponent } from '~types/popupStore'

import { type AutoTest, useCommon, useWindowSize, vOnKeyPress } from '@arora/common'

import { motion } from 'motion-v'

function makeClass(str: string, additional?: string | null | undefined): string {
  return additional
    ? `v-popup-${camelCaseConvert(str)}__${additional}`
    : `v-popup-${camelCaseConvert(str)}`
}

const popupStore = usePopupStore()

const appConfig = useAppConfig()
const { eventOn } = useEmitter()
const { camelCaseConvert } = useCommon()
const { translate } = useI18nSanitized()
const { isSmall } = useWindowSize()

const captchaVisible = ref<boolean>(false)

eventOn('v-captcha-visible', () => {
  captchaVisible.value = true
})
eventOn('v-captcha-hidden', () => {
  captchaVisible.value = false
})

const className = ref<string>('')

const autoTest = ref<AutoTest>({})

async function outsideClick(event: MouseEvent): Promise<void> {
  const element = event.target as HTMLElement

  if (
    element.className !== 'v-popup' ||
    (element.className === 'v-popup' && element.clientWidth <= event.clientX)
  )
    return

  popupStore.closePopupWithShake()
}

const popupPlaceCloseButtonInside = computed<boolean>(() => {
  if (popupStore.popupComponent === 'bannerStories' && isSmall.value) return true
  return appConfig.VueSettingsPreRun.PopupPlaceCloseButtonInside
})

const hash = ref<string>('')

watch(
  () => popupStore.popupComponent,
  async (newContent: PopupComponent | null | undefined) => {
    if (newContent) {
      hash.value = window.btoa(JSON.stringify(popupStore.popupProperties))
      autoTest.value = popupStore.dataTestId
        ? {
            dataTestId: popupStore.dataTestId
          }
        : {
            dataTestId: camelCaseConvert(newContent)
          }
      className.value = makeClass(
        newContent,
        newContent === 'menuProductPopup'
          ? appConfig.VueSettingsPreRun.ProductLayout
          : null
      )
    }
  }
)

function toggleKeyPress(event: KeyboardEvent): void {
  if (event.code === 'Escape') {
    popupStore.closePopupWithShake()
  }
}
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-popup-mobile-wrapper {
  padding: 15px;
  max-height: 90vh;

  .v-popup-mobile-lower {
    height: 15px;
  }
}

.v-popup-mobile-recaptcha-content {
  min-height: 60vh;
}

.v-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1061;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: variables.$PopupOverlayBackground;
  backdrop-filter: blur(10px);

  .v-popup-wrapper {
    position: relative;
    width: auto;
    margin: 1.75rem auto;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - 3.5rem);

    @include mixins.popup-size;

    &.v-popup-close-inside {
      .v-popup-head {
        @include mixins.sm {
          padding-right: 35px;
        }
      }
    }

    @include mixins.sm {
      margin: 5px auto 0;
      min-height: calc(100% - 1.75rem);
    }
  }

  .v-popup-wrapper__recaptcha {
    min-height: 415px;
  }

  &-content {
    @include mixins.trans;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    outline: 0;
    background: variables.$PopupBackground;
    color: variables.$PopupColor;
    pointer-events: auto;

    border-radius: variables.$BorderRadius;
    padding: variables.$PopupPadding;
    transform: translate(0, -50px);
    margin: 0;

    @include mixins.sm {
      padding: 18px 22px;
      transform: none;
      margin: 5px 16px 0;
    }

    &--close-outside {
      @include mixins.md {
        margin: 50px 16px 0; //50 is for close button
      }
    }
  }
  .v-popup-account-management-popup {
    .v-popup-content {
      @include mixins.sm {
        padding: variables.$PopupPadding;
      }
    }
  }

  .v-popup-gallery-popup {
    @include mixins.popup-size--large;

    .v-popup-content {
      border-radius: variables.$BorderRadius;
      padding: 0;
      background: transparent;
      display: flex;
      align-items: center;
    }
  }

  .v-popup-menu-search-popup {
    @include mixins.popup-size;

    .v-popup-content {
      border-radius: variables.$BorderRadius;
      padding: 0;
      background: transparent;
      display: flex;
      align-items: center;
    }
  }

  .v-popup-banner-stories {
    @include mixins.popup-size;

    width: auto;

    display: flex;
    align-items: center;

    .v-popup-content {
      width: auto;
      border-radius: variables.$BorderRadius;
      padding: 0;
      background: transparent;
    }

    .v-popup-close-button-inside {
      right: 35px;
      top: 27px;

      @include mixins.sm {
        right: 8px;
        top: 24px;
      }

      svg {
        width: 24px;
        height: 24px;

        path {
          stroke: variables.$BodyElementsBackground;
        }
      }
    }
  }

  .v-popup-account-change-password-popup,
  .v-popup-account-edit-profile-popup,
  .v-popup-account-points-history,
  .v-popup-cart-cash-change,
  .v-popup-cart-cleanup-popup,
  .v-popup-confirm-popup,
  .v-popup-easter-egg-popup,
  .v-popup-input-popup,
  .v-popup-message-popup,
  .v-popup-ok-popup-form,
  .v-popup-order-repeat-redirect-popup,
  .v-popup-partial-pay-popup,
  .v-popup-pay-method-bindings-popup,
  .v-popup-promo-in-url-popup,
  .v-popup-qr-app-popup,
  .v-popup-account-change-phone-popup {
    @include mixins.popup-size--small;
  }

  .v-popup-account-order-details-popup,
  .v-popup-account-order-repeat-popup,
  .v-popup-account-ranks-popup,
  .v-popup-address-selector-popup,
  .v-popup-cart-birthday-gift-popup,
  .v-popup-cart-changed-products-popup,
  .v-popup-city-selector-popup,
  .v-popup-error-popup,
  .v-popup-feedback-image-popup,
  .v-popup-feedback-popup,
  .v-popup-maps-saved-addresses-popup,
  .v-popup-menu-constructor-popup-small,
  .v-popup-menu-slots-lunch-mobile-popup,
  .v-popup-message-popup-standard-size,
  .v-popup-partners-popup,
  .v-popup-saved-address-popup,
  .v-popup-table-reservation-popup,
  .v-popup-vacancy-popup {
    @include mixins.popup-size;
  }

  .v-popup-account-delete-popup,
  .v-popup-account-management-popup,
  .v-popup-delivery-time-rath-celerdain-popup,
  .v-popup-delivery-time-rath-dinen-popup {
    @include mixins.popup-size--medium;
  }

  .v-popup-qr-pay-popup {
    @include mixins.popup-size;
    padding-bottom: 1.1rem;
  }
  .v-popup-menu-constructor-popup {
    @include mixins.popup-size--extra-large;
  }
  .v-popup-offer-choice-form {
    @include mixins.popup-size--large;
  }
  .v-popup-menu-gifts-popup,
  .v-popup-menu-slots-half-popup,
  .v-popup-menu-slots-lunch-popup,
  .v-popup-menu-slots-quarter-popup {
    @include mixins.popup-size--extra-large;
  }

  .v-popup-menu-product-popup {
    &__KhazadDum {
      @include mixins.popup-size--large;
    }
    &__Moria {
      @include mixins.popup-size;
    }
    &__Mazarbul {
      @include mixins.popup-size--large;

      .v-popup-content {
        padding: 0;
      }
    }
  }
}

.v-popup-close-button {
  cursor: pointer;
  z-index: 1061;

  color: variables.$PopupOverlayColor;

  &-outside {
    position: absolute;
    top: -5px;
    right: -40px;

    &:hover {
      svg {
        opacity: 1;
      }
    }

    @include mixins.md {
      top: -50px;
      right: 20px;
    }

    svg {
      width: 40px;
      height: 40px;

      @include mixins.trans;
      opacity: 0.75;
    }
  }

  &-inside {
    font-size: 24px;
    position: absolute;
    right: 20px;
    top: 15px;

    &:hover {
      svg {
        path {
          stroke: variables.$BodyTextColor;
        }
      }
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        @include mixins.trans;
        stroke: variables.$BodyTextColorLight;
      }
    }
  }
}

.v-popup-buttons {
  margin-top: 32px;
}

.v-popup-shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
